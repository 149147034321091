import axios from 'axios'

export const isDevelopment = process.env.NODE_ENV === 'development'

export const client = axios.create({
	baseURL: window.__VAP_GLOBALS__.config.rootUrl
})

export const clientMock = axios.create({
	//baseURL: 'https://1855b68a-0eb3-433b-bbeb-b2719f5122e8.mock.pstmn.io'
	baseURL: 'https://f1b6892a-803c-4f6b-8f4d-5ab8f0f60085.mock.pstmn.io'
})

export default client
