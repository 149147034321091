import type { Entity, Report } from 'libs/global'
import create from 'zustand'

type GlobalState = {
	clearSelectedReport: () => void
	isSignIn: boolean
	selectedEntity: Entity
	selectedReport: Report
	setIsSignIn: (isLogin: boolean) => void
	setSelectedEntity: (entity: Entity) => void
	setSelectedReport: (report: Report) => void
	setTenantName: (tenantName: string) => void
	tenantName: string
}

export const useGlobalStore = create<GlobalState>(set => ({
	isSignIn: false,
	setIsSignIn: (isSignIn: boolean) => set({ isSignIn }),
	tenantName: null,
	setTenantName: (tenantName: string) => set({ tenantName }),
	selectedEntity: null,
	setSelectedEntity: (selectedEntity: Entity) => set({ selectedEntity }),
	selectedReport: null,
	setSelectedReport: (selectedReport: Report) => set({ selectedReport }),
	clearSelectedReport: () => set({ selectedReport: null })
}))
