import * as React from 'react'
import { useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import AnnotationLayer from './AnnotationLayer'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

type pdfProps = { id: string; report: any }

const ReactPDF = (props: pdfProps) => {
	const [numPages, setNumPages] = useState(null)
	const [pdfData, setPdfData] = useState(null)

	const onDocumentLoadSuccess = ({ numPages }): void => {
		setNumPages(numPages)
	}

	useEffect(() => {
		if (props.report) {
			setPdfData(`data:application/pdf;base64,${props.report}`)
		}
	}, [props.report])

	return (
		<div id={props.id} style={{ overflow: 'auto' }}>
			<AnnotationLayer />
      <div className="printArea">
			{pdfData && (
				<Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess} renderMode="svg">
					{Array.from(new Array(numPages), (el, index) => (
						<Page key={`page_${index + 1}`} pageNumber={index + 1} width={1040} />
					))}
				</Document>
			)}
      </div>
		</div>
	)
}

export default ReactPDF
