export const time1Day = 86400000
export const time1Hour = 3600000
export const time1Minute = 60000
export const time30Minutes = 1800000
export const time30Seconds = 30000
export const time5Minutes = 300000

export const queries = {
	global: {
		auth: 'auth',
		me: 'me',
		reports: 'reports',
		embeddedConfig: 'embeddedConfig'
	}
}
