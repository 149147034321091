import { ReportData } from 'libs/global'
import React, { useEffect, useState } from 'react'

import { useGlobalStore } from 'stores/global'

import { ImageWrap } from './styles'

type ReportProps = {
	printDone: Function
	printNow: boolean
	reportData: ReportData
}

const Image = (props: ReportProps) => {
	const { reportData } = props
	const [showImage, setShowImage] = useState(false)

	useEffect(() => {
		if (reportData && reportData.fileBlob) {
			setShowImage(true)
		}
	}, [props.reportData])

	return (
		<div className="printArea">
			<ImageWrap>{showImage && <img src={'data:image/bmp;base64,' + reportData.fileBlob.file} />}</ImageWrap>
		</div>
	)
}

export default Image
