import { Box, T } from '@veracity/vui'
import shallow from 'zustand/shallow'

import { useGlobalStore } from 'stores/global'

import { BreadCrumbText } from './styles'
const BreadCrumb = () => {
	const { selectedEntity, selectedReport } = useGlobalStore(
		state => ({
			selectedEntity: state.selectedEntity,
			selectedReport: state.selectedReport
		}),
		shallow
	)

	return (
		<Box>
			{selectedEntity && <BreadCrumbText text={selectedEntity.name}></BreadCrumbText>}
			<Box mx="10px">/</Box>
			{selectedReport && <BreadCrumbText text={selectedReport.name}></BreadCrumbText>}
		</Box>
	)
}

export default BreadCrumb
