import { client, clientMock } from '../client'

export const getReports = (tenantDomain: string) => {
    return client.get(`/webapi/report/tenant/${tenantDomain}`).then(response => response.data)
}

export const getEmbeddedConfig = (entityId: number, reportId: number) => {
    return client
        .get(`/webapi/report/config/entity/${entityId}/report/${reportId}`)
        .then(response => response.data)
        .catch(res => Promise.reject(res))
}

export const logUsage = (entityTreeId: number, reportId: number) => {
    return client
        .get(`/webapi/report/usage/entity/${entityTreeId}/report/${reportId}`)
        .then(response => response.data)
        .catch(res => Promise.reject(res))
}

export const logPageView = (entityTreeId: number, reportId: number, usageId: number, pageName: string) => {
    return client
        .get(`/webapi/report/pageview?entityTreeId=${entityTreeId}&reportId=${reportId}&usageId=${usageId}&pageName=${pageName}`)
        .then(response => response.data)
        .catch(res => Promise.reject(res))
}

export const getExportFile = (entityId: Number, reportId: Number, format: string, bookmark: any, callback: (err) => any) => {
    const formData = {
        EntityId: entityId,
        ReportId: reportId,
        Format: format,
        Bookmark: bookmark
    }

    return client
        .post('/webapi/report/exportToFile', formData, {
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(response => response.data)
        .catch(err => Promise.reject(err.response?.data))
}
