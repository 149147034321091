import { Box } from '@veracity/vui'
import React from 'react'
import { Outlet } from 'react-router-dom'

import { Footer, Header } from 'global'

const Layout = () => {
	return (
		<Box column justify="space-between" minH="100vh" w="100vm">
			<Header />
			<Outlet />
			<Footer />
		</Box>
	)
}

export default Layout
