import { Box, Footer, useFooterLinks, useLoadLinks } from '@veracity/vui'
import React from 'react'

const GlobalFooter = () => {
    const appLinks = [
        {
            id: 'link-platform',
            text: 'Platform',
            items: [
                {
                    id: 'about',
                    text: 'About Veracity',
                    url: 'https://www.veracity.com/about?loggedin=1'
                },
                {
                    id: 'news',
                    text: 'News',
                    url: 'https://www.veracity.com/news?loggedin=1'
                },
                {
                    id: 'marketplace',
                    text: 'Marketplace',
                    url: 'https://store.veracity.com/?loggedin=1'
                },
                {
                    id: 'veracityTrustCenter',
                    text: 'Veracity trust center',
                    url: 'https://www.veracity.com/veracity-trust-center?loggedin=1'
                },
                {
                    id: 'career',
                    text: 'Career',
                    url: 'https://www.veracity.com/career?loggedin=1'
                }
            ]
        },
        {
            id: 'link-products',
            text: 'Products',
            items: [
                {
                    id: 'veracityConnectedArticle',
                    text: 'Veracity Connected app',
                    url: 'https://www.veracity.com/article/veracity-connected-bringing-veracity-to-your-mobile?loggedin=1'
                },
                {
                    id: 'dataFabricProduct',
                    text: 'Veracity Data Fabric',
                    url: 'https://store.veracity.com/veracity-data-fabric-secure-data-sharing?loggedin=1'
                },
                {
                    id: 'assetConnectArticle',
                    text: 'Asset Connect',
                    url: 'https://www.veracity.com/article/veracity-asset?loggedin=1'
                },
                {
                    id: 'vapProduct',
                    text: 'Veracity Adapter for Power BI (VAP)',
                    url: 'https://store.veracity.com/veracity-adapter-for-power-bi-vap?loggedin=1'
                },
                {
                    id: 'analytics',
                    text: 'Veracity Analytics',
                    url: 'https://analytics.veracity.com/?loggedin=1'
                }
            ]
        },
        {
            id: 'link-partnership',
            text: 'Partnership',
            items: [
                {
                    id: 'sellingOnVeracityArticle',
                    text: 'Selling on Veracity',
                    url: 'https://developer.veracity.com/article/are-you-ready-to-sell-on-veracity?loggedin=1'
                },
                {
                    id: 'partnerProgram',
                    text: 'Veracity partner program',
                    url: 'https://www.veracity.com/partner-integration?loggedin=1'
                }
            ]
        },
        {
            id: 'link-developer',
            text: 'Developer',
            items: [
                {
                    id: 'developer',
                    text: 'Veracity for Developers',
                    url: 'https://developer.veracity.com/?loggedin=1'
                },
                {
                    id: 'documentation',
                    text: 'Documentation',
                    url: 'https://developer.veracity.com/docs?loggedin=1'
                },
                {
                    id: 'developerCommunity',
                    text: 'Developer Community',
                    url: 'https://community.veracity.com/?loggedin=1'
                }
            ]
        },
        {
            id: 'link-support',
            text: 'Support',
            items: [
                {
                    id: 'link-helpcenter',
                    text: 'Help Center',
                    url: 'https://help-center.veracity.com/?loggedin=1'
                },
                {
                    id: 'link-veracitystatus',
                    text: 'Veracity Status',
                    url: 'https://status.veracity.com/?loggedin=1'
                },
                {
                    id: 'link-veracitysupport',
                    text: 'Veracity Support',
                    url: 'https://support.veracity.com/?loggedin=1'
                }
            ]
        },
        {
            id: 'linkg-leagal',
            text: 'Legal',
            items: [
                {
                    id: 'privacyStatement',
                    text: 'Privacy statement',
                    url: 'https://services.veracity.com/PrivacyStatement?loggedin=1'
                },
                {
                    id: 'termsOfUse',
                    text: 'Terms of use',
                    url: 'https://id.veracity.com/terms-of-use?loggedin=1'
                },
                {
                    id: 'cookiePolicy',
                    text: 'Cookies policy',
                    url: 'https://services.veracity.com/CookieList?loggedin=1'
                }
            ]
        }
    ]

    return (
        <Box bg="darkBlue.18" display="block" h="48px">
            <Footer applicationLinks={appLinks} isApplication isSlim variant="developer" />
        </Box>
    )
}

export default GlobalFooter
