import React from 'react'
import { render } from 'react-dom'
import { createRoot } from 'react-dom/client'

import App from './app'

import '@popperjs/core'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)
