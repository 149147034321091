import { AccordionItem, Box, Button, Icon, Menu, Tooltip } from '@veracity/vui'
import { ReportData } from 'libs/global'

import { BookmarkItem, BookmarkList, ExportItem, ExportItemList, ListItemText, TooltipText } from './styles'

type ToolbarProps = {
    bookmarks: Array<any>
    handleBookmarkLink: Function
    handleBookmarkSelect: Function
    handleDownloadFile: Function
    handleExportFile: Function
    handleFullScreen: Function
    handlePrint: Function
    reportData: ReportData
}

const Toolbar = (props: ToolbarProps) => {
    const { reportData } = props

    return (
        <Box alignItems="center" className="toolbar" justifyContent="space-between" mr="10">
            {reportData.enableDownload && reportData.fileBlob && reportData.fileBlob.file && (
                <Tooltip placement="bottom" text="Download">
                    <Button icon="culFileDownloadAlt" ml="12" onClick={() => props.handleDownloadFile()} size="md" title=" " variant="tertiaryDark" />
                </Tooltip>
            )}
            {reportData?.enablePrint && (
                <Tooltip placement="bottom" text="Print">
                    <Button icon="falPrint" ml="12" onClick={() => props.handlePrint()} size="md" title=" " variant="tertiaryDark" />
                </Tooltip>
            )}

            {(reportData?.enableExportPDF || reportData?.enableExportPPT) && (
                <Menu>
                    <Tooltip placement="bottom" text="Export">
                        <Menu.Button className="hide-dropdown" icon="culFileDownloadAlt" isDropDown ml="12" title=" " />
                    </Tooltip>

                    <Menu.List className="export-list">
                        {reportData.enableExportPDF && (
                            <Menu.Item key="ExportAsPDF" onClick={() => props.handleExportFile('pdf')}>
                                <Icon cursor="pointer" name="falFilePdf" size="sm" />
                                <span>&nbsp;&nbsp;PDF</span>
                            </Menu.Item>
                        )}
                        {reportData.enableExportPPT && (
                            <Menu.Item key="ExportAsPPT" onClick={() => props.handleExportFile('pptx')}>
                                <Icon cursor="pointer" name="falFilePowerpoint" size="sm" />
                                <span>&nbsp;&nbsp;PPT</span>
                            </Menu.Item>
                        )}
                    </Menu.List>
                </Menu>
            )}
            {reportData.enableBookmarks && reportData.showReportBookmarks && ['PBI', 'PREMIUM PBI'].indexOf(reportData.reportType) >= 0 && (
                <div key={props.reportData.id} style={{ display: 'inline-flex' }}>
                    {props.bookmarks && props.bookmarks.length > 0 && (
                        <Menu>
                            <Tooltip placement="bottom" text="Bookmark">
                                <Menu.Button className="hide-dropdown" icon="falBookmark" isDropDown ml="12" title=" " />
                            </Tooltip>

                            <Menu.List className="bookmark-list" heading="Bookmark">
                                {props.bookmarks.map(bm => {
                                    return !bm.state && bm.children ? (
                                        <AccordionItem collapsed={true} fontSize="1rem" id={bm.name} key={bm.name} title={bm.displayName}>
                                            <Box className="collapsed-list" column w="100%">
                                                {bm.children.map(bmc => (
                                                    <Menu.Item className={bmc.selected ? 'selected bookmark-item' : 'bookmark-item'} key={bmc.name} w="100%">
                                                        <Box className="bookmark-name" onClick={() => props.handleBookmarkSelect(bmc)}>
                                                            <ListItemText>{bmc.displayName}</ListItemText>
                                                        </Box>
                                                        <Button icon="falCopy" onClick={() => props.handleBookmarkLink(bmc)} size="md" title=" " variant="tertiaryDark" />
                                                    </Menu.Item>
                                                ))}
                                            </Box>
                                        </AccordionItem>
                                    ) : (
                                        <Menu.Item className={bm.selected ? 'selected bookmark-item' : 'bookmark-item'} key={bm.state}>
                                            <Box className="bookmark-name" onClick={() => props.handleBookmarkSelect(bm)}>
                                                <ListItemText>{bm.displayName}</ListItemText>
                                            </Box>
                                            <Button icon="falCopy" onClick={() => props.handleBookmarkLink(bm)} size="md" title=" " variant="tertiaryDark" />
                                        </Menu.Item>
                                    )
                                })}
                            </Menu.List>
                        </Menu>
                    )}
                </div>
            )}
            {props.handleFullScreen && <Button icon="falExpandAlt" ml="12" onClick={() => props.handleFullScreen()} size="md" title=" " variant="tertiaryDark" />}
        </Box>
    )
}

export default Toolbar
