import { styled } from '@veracity/vui'

export const ImageWrap = styled.div`
	width: 100%;
	height: 100%;
	margin: 0 auto;
	overflow: auto;
	padding: 0.5rem 0;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		display: block;
		width: 100%;
		margin: 0 auto;
	}
`
