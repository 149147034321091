const PublicLabel = {
    exportFileTitle: 'Export to $fileType$ in progress',
    exportFileCompleteTitle: '$fileType$ is ready for download',
    exportFileErrorTitle: 'Error occurs during export',
    exportFileNoData: 'Export to file failed, please try again.',
    exportWrongConfig:
        'The report export failed as it seems to be configureed incorrectly, please find support contact from the footer and contact the admin of the current VAP service for help.',
    exportBadRequest: 'Something wrong with the report export, please find support contact from the footer and contact the admin of the current VAP service for help.',
    exportFileContent: 'Your report $reportName$ is being exported to a $fileType$ file. This might take a few minutes.',
    exportFileCompleteContent: 'The report $reportName$ was exported to a $fileType$ file and is available for you to download.',
    exportFileCompleteNote:
        'Please note: The links in the Power Point to the report can not be used. Suggest you copy the link path presented in your browser now. Then open the Power Point and change the link on every page/slide in the Power Point.'
}

export default PublicLabel
