import { styled } from '@veracity/vui'

export const ReportTab = styled.div`
	padding: 0.2rem 2rem;

	.activeTab {
		color: #333;
		font-weight: bold;
		text-decoration: underline;
	}

	a {
		font-size: 13px;
		cursor: pointer;
		color: #333;

		span {
			font-size: 10px;
			padding-left: 15px;
			padding-right: 5px;
		}
	}
`

export const TabVl = styled.span`
	border-left: 1px solid gray;
	height: 20px;
	margin-left: 15px;
	margin-right: 15px;
	top: 0;
`

export const ReportTitle = styled.div`
	text-align: center;
	margin: 10px;
	font-size: 18px;
`

export const ReportFrame = styled.div`
	width: 100%;
	height: 100%;

	iframe {
		border: none;
	}
`
