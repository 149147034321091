import { styled } from '@veracity/vui'

export const BookmarkList = styled.ul`
	min-width: 20rem;
	border-radius: 0;
	padding: 0;
  position: absolute;
  z-index: 1000;
  display: none;
  margin: 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ECE9E9;
  &.show {
    display: block;
  }
}
`
export const BookmarkItem = styled.li`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	line-height: 2rem;
	border-bottom: 1px dashed #b7b7b7;
	padding: 5px 0.5rem;
	:hover {
		background-color: #4189c7;
		color: #fff;
	}
	&.selected {
		background: rgb(0, 53, 145);
		color: #ffffff;
	}
	:last-of-type {
		border-bottom: none;
	}
	.bookmark-name {
		min-width: 15rem;
	}
	.bookmark-link {
		padding: 0 10px;
		display: flex;
		align-items: center;
	}
`

export const ExportItemList = styled.ul`
	min-width: 12rem;
	border-radius: 0;
	padding: 0;
	position: absolute;
	z-index: 1000;
	display: none;
	margin: 0;
	font-size: 1rem;
	color: #000;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ece9e9;
	&.show {
		display: block;
	}
`

export const ExportItem = styled.li`
	cursor: pointer;
	display: flex;
	align-items: center;
	line-height: 2rem;
	border-bottom: 1px dashed #b7b7b7;
	padding: 5px 0.5rem;
	:hover {
		background-color: #4189c7;
		color: #fff;
	}
	:last-of-type {
		border-bottom: none;
	}
`

export const ListItemText = styled.span`
	width: 100%;
  align-items: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
`

export const TooltipText = styled.div`
	align-items: center;
	color: #fff;
	background-color: rgb(15, 33, 77);
	padding: 0.5rem 0.75rem;
	z-index: 9999;
`
