import { ListItem, styled } from '@veracity/vui'

export const SelectedListItem = styled(ListItem)`
	padding-left: 0;
  overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	&:before {
		content: '';
		height: 100%;
		width: 5px;
		background-color: #003f79;
	}
`

export const OverflowText = styled.span`
	width: 100%;
  align-items: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
  text-align: left;
}
`
export const TooltipDiv = styled.div`
	background: rgb(0, 42, 62);
	color: #fff;
	padding: 10px;
`
