import { Box, LinksProvider, T, VuiProvider } from '@veracity/vui'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Dashboard, Layout } from 'pages'

import './styles/main.scss'
const queryClient = new QueryClient()

const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <VuiProvider>
                <LinksProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route element={<Layout />}>
                                <Route element={<Dashboard />} path="/:tenant" />
                                <Route element={<Dashboard />} path="/:tenant/entity/:eid/report/:rid" />
                                <Route
                                    element={
                                        <Box alignItems="center" justifyContent="center">
                                            <T size="lg">Welcome to VAP Public View! Please specify /[tenant] in your URL to access public reports.</T>
                                        </Box>
                                    }
                                    path="*"
                                />
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </LinksProvider>
            </VuiProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}

export default App
