import { Box } from '@veracity/vui'
import { enterFullScreen, exitFullScreen, isFullScreen } from 'libs/global'
import React, { useRef } from 'react'

import ReportSelector from '../reportselector/reportselector'

const RightPanel = () => {
	const rightPanel = useRef<HTMLDivElement>(null)

	const toggleFullScreen = (e: any) => {
		setFullScreen()
	}

	const setFullScreen = () => {
		if (!isFullScreen()) {
			enterFullScreen(rightPanel.current)
		} else {
			exitFullScreen()
		}
	}

	return (
		<Box bg="#fff" column h="100%" px="10px" py="8px" ref={rightPanel} w="80%">
			<ReportSelector handleFullScreen={toggleFullScreen}></ReportSelector>
		</Box>
	)
}

export default RightPanel
