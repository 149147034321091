import { Box } from '@veracity/vui'
import { EmbeddedConfig, ReportData } from 'libs/global'
import * as pbi from 'powerbi-client'
import React, { useEffect, useRef, useState } from 'react'
import shallow from 'zustand/shallow'

import { logPageView } from 'apis/global'
import { useLogUsage } from 'queries/global'
import { useGlobalStore } from 'stores/global'

import { ReportFrame, ReportTab, ReportTitle, TabVl } from './styles'

declare let powerbi: pbi.service.Service

type ReportProps = {
    currentBookmark: any
    printDone: Function
    printNow: boolean
    reportConfig: EmbeddedConfig
    reportData: ReportData
    setupBookmarks: (bookmarks, report) => void
}

const Report = (props: ReportProps) => {
    const { reportConfig, reportData } = props
    const [activePageTitle, setActivePageTitle] = useState(null)
    const [pages, setPages] = useState(null)
    const [report, setReport] = useState<pbi.Report>(null)
    const [usageId, setUsageId] = useState(null)
    const rootElement = useRef<HTMLDivElement>(null)
    const { selectedEntity, selectedReport } = useGlobalStore(
        state => ({
            selectedEntity: state.selectedEntity,
            selectedReport: state.selectedReport
        }),
        shallow
    )

    const handleUsageResult = data => {
        setUsageId(data)
        logPageUsage(data)
    }

    const { mutate: logUsage } = useLogUsage(selectedEntity.entityTreeId, selectedReport.id, handleUsageResult)

    const setReportConfig = (reportConfig: EmbeddedConfig, reportData: ReportData) => {
        const config: pbi.IEmbedConfiguration = {}
        config.settings = {}
        config.type = 'report'
        config.tokenType = pbi.models.TokenType.Embed
        config.accessToken = reportConfig?.accessToken
        config.embedUrl = reportConfig?.embeddedUrl
        config.id = reportConfig?.reportId
        config.permissions = pbi.models.Permissions.All

        if (reportData.reportType !== 'RDL') {
            config.settings = {}
            config.settings.filterPaneEnabled = reportData?.enableFilterPanel || false
            config.settings.navContentPaneEnabled = false
            const enableExportData: pbi.models.CommandDisplayOption = reportData?.enableExport ? pbi.models.CommandDisplayOption.Enabled : pbi.models.CommandDisplayOption.Hidden
            config.settings.commands = [
                {
                    exportData: {
                        displayOption: enableExportData
                    }
                }
            ]
        }

        return config
    }

    const config = setReportConfig(reportConfig, reportData)

    const validateConfig = (config: pbi.IEmbedConfiguration) => {
        const errors = pbi.models.validateReportLoad(config)
        if (errors) {
            //console.log(errors)
        }
    }
    const embed = (config: pbi.IEmbedConfiguration): pbi.Embed => {
        validateConfig(config)
        powerbi.reset(rootElement.current)
        return powerbi.embed(rootElement.current, config)
    }

    const getCustomTitleStyle = (pageName: string) => {
        let result = reportData.showEntityName ? selectedEntity.name : ''
        result += (result.length > 0 && reportData.showReportName ? ' - ' : '') + (reportData.showReportName ? selectedReport.name : '')
        result += (result.length > 0 && reportData.showPageName ? ' - ' : '') + (reportData.showPageName ? pageName : '')
        return result
    }

    const applyBookMark = bookMark => {
        bookMark &&
            bookMark.state &&
            report.bookmarksManager.applyState(bookMark.state).then(() => {
                if (pages) {
                    report.getPages().then(pages => {
                        setPages(pages.filter(x => x.visibility !== 1))
                        logPageUsage(usageId)
                    })
                }
            })
    }

    const loadReport = () => {
        report.on('loaded', e => {
            if (report.bookmarksManager) {
                report.bookmarksManager
                    .getBookmarks()
                    .then(bookMarks => {
                        props.setupBookmarks(bookMarks, report)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }

            report
                .getPages()
                .then(data => {
                    const tmpPages = data.filter(x => x.visibility !== 1)
                    setPages(tmpPages)
                    const activePage = data.find(p => p.isActive)
                    !activePage && report.setPage(tmpPages?.[0].name)
                    logUsage()
                })
                .catch(e => {
                    console.log(e)
                })
        })
    }

    const handlePageChange = (page: pbi.Page) => {
        report.setPage(page.name)
        report.getPages().then(pages => {
            setPages(pages.filter(x => x.visibility !== 1))
            logPageUsage(usageId)
        })
    }

    const logPageUsage = (usageId: number) => {
        report.getActivePage().then(page => {
            if (page && usageId) {
                logPageView(selectedEntity.entityTreeId, selectedReport.id, usageId, page.displayName)
            }
        })
    }

    useEffect(() => {
        report && report.print()
        props.printDone()
    }, [props.printNow])

    useEffect(() => {
        report && applyBookMark(props.currentBookmark)
    }, [props.currentBookmark])

    useEffect(() => {
        if (rootElement.current) {
            setReport(embed(config) as pbi.Report)
        }
    }, [rootElement.current])

    useEffect(() => {
        if (rootElement.current) {
            setPages(null)
            setActivePageTitle(null)
            setReport(embed(config) as pbi.Report)
        }
    }, [props.reportConfig])

    useEffect(() => {
        report && loadReport()
    }, [report])

    useEffect(() => {
        if (pages?.length > 0) {
            const activePage = pages?.find(p => p.isActive)
            const result = getCustomTitleStyle(activePage?.displayName ?? '')
            setActivePageTitle(result)
        } else {
            setActivePageTitle(null)
        }
    }, [selectedEntity, selectedReport, pages])

    return (
        <Box column h="100%" w="100%">
            <ReportTab>
                {reportData.showReportPageMenu &&
                    pages &&
                    pages.map((page: pbi.Page, index) => {
                        return (
                            <span key={index}>
                                {index !== 0 && <TabVl></TabVl>}
                                <a className={page.isActive ? 'activeTab' : ''} data-index={index} onClick={() => handlePageChange(page)}>
                                    {page.displayName}
                                </a>
                            </span>
                        )
                    })}
            </ReportTab>
            {reportData?.showCustomTitle && activePageTitle && (
                <ReportTitle>
                    <span>{activePageTitle}</span>
                </ReportTitle>
            )}
            <ReportFrame className="powerbi-frame" ref={rootElement}></ReportFrame>
        </Box>
    )
}

export default Report
