import type { EmbeddedConfig, TenantReport } from 'libs/global'
import { useMutation, useQuery } from 'react-query'

import { getEmbeddedConfig, getReports, logPageView, logUsage } from 'apis/global'
import { queries, time30Minutes } from 'queries/consts'

export const useReports = (tenantDomain: string) => {
    return useQuery<TenantReport, any>([queries.global.reports], () => getReports(tenantDomain), {
        cacheTime: time30Minutes,
        staleTime: time30Minutes,
        retry: 1,
        refetchOnWindowFocus: false
    })
}

export const useEmbeddedConfig = (entityId: number, reportId: number) => {
    return useQuery<any, any>([queries.global.embeddedConfig, entityId, reportId], () => getEmbeddedConfig(entityId, reportId), {
        cacheTime: time30Minutes,
        staleTime: time30Minutes,
        retry: 1,
        refetchOnWindowFocus: false
    })
}

export const useLogUsage = (entityTreeId: number, reportId: number, callback?: Function) => {
    const mutation = useMutation(() => logUsage(entityTreeId, reportId), {
        onSuccess: data => callback && callback(data),
        onError: err => {
            //todo: show error
        }
    })
    return mutation
}
