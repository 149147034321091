export const enterFullScreen = (element: HTMLElement) => {
	if (!isFullScreen()) {
		const ele = element as any
		if (ele.requestFullscreen) {
			ele.requestFullscreen()
		} else if (ele.mozRequestFullScreen) {
			ele.mozRequestFullScreen()
		} else if (ele.webkitRequestFullscreen) {
			ele.webkitRequestFullscreen()
		} else if (ele.msRequestFullscreen) {
			ele.msRequestFullscreen()
		}
	}
}

export const exitFullScreen = () => {
	if (isFullScreen()) {
		const doc = document as any
		if (doc.exitFullscreen) {
			doc.exitFullscreen()
		} else if (doc.mozCancelFullScreen) {
			doc.mozCancelFullScreen()
		} else if (doc.webkitExitFullscreen) {
			doc.webkitExitFullscreen()
		} else if (doc.msExitFullscreen) {
			doc.msExitFullscreen()
		}
	}
}

export const isFullScreen = () => {
	return (
		document.fullscreenElement ||
		(document as any).mozFullScreenElement ||
		(document as any).webkitFullScreenElement ||
		(document as any).msFullscreenElement ||
		false
	)
}

export const isFullscreenEnabled = () => {
	return (
		document.fullscreenEnabled ||
		(document as any).mozFullScreenEnabled ||
		(document as any).webkitFullscreenEnabled ||
		(document as any).msFullscreenEnabled ||
		false
	)
}
