import { Box, Link, Spinner, T } from '@veracity/vui'
import { ApiError, TenantReport } from 'libs/global'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { useReports } from 'queries/global'
import { useGlobalStore } from 'stores/global'

import LeftPanel from './leftpanel/leftpanel'
import RightPanel from './rightpanel/rightpanel'

const Dashboard = () => {
	const { tenant, eid, rid } = useParams<{ eid: string; rid: string; tenant: string }>()
	const navigate = useNavigate()
	const { tenantName, setTenantName } = useGlobalStore(
		state => ({
			tenantName: state.tenantName,
			setTenantName: state.setTenantName
		}),
		shallow
	)
	const { data, isLoading, isError, error } = useReports(tenant)

	const isMatched = (t: TenantReport, eId: number, rId: number): boolean => {
		let matched = false
		t.tenant.entities.forEach(e => {
			e.reports.forEach(r => {
				if (r.id === rId && e.entityTreeId === eId) {
					matched = true
				}
			})
		})
		return matched
	}

	const hasMatchedReport = (data: TenantReport, eId: string, rId: string): boolean => {
		if (eId && rId) {
			if (Number.isNaN(eId) || Number.isNaN(rId) || !isMatched(data, +eId, +rId)) return false
		}
		return true
	}

	const hasAvailableReports = (data: TenantReport): boolean => {
		if (!data.tenant) return false
		else if (data.tenant.entities.length === 0) return false
		return true
	}

	const gotoHome = () => {
		navigate(`/${tenantName}`)
	}

	useEffect(() => {
		setTenantName(tenant)
	}, [tenant])

	if (isLoading) {
		return (
			<Box alignItems="center" h="calc(100vh - 180px)" justifyContent="center" mt="0">
				<Spinner size="lg" text="Loading…" textPosition="bottom" />
			</Box>
		)
	}

	if (isError) {
		return (
			<Box alignItems="center" h="calc(100vh - 180px)" justifyContent="center" mt="0">
				<T size="lg" text={(error?.response?.data as ApiError).displayMessage} />
			</Box>
		)
	}

	if (!hasAvailableReports(data)) {
		return (
			<Box alignItems="center" column h="calc(100vh - 180px)" justifyContent="center" mt="0">
				<T
					size="lg"
					text="Can't open the public report. No entities are enabled for public view. "
				/>
			</Box>
		)
	}

	if (!hasMatchedReport(data, eid, rid)) {
		return (
			<Box alignItems="center" column h="calc(100vh - 180px)" justifyContent="center" mt="0">
				<T
					size="lg"
					text="Can't open the public report. No entities are enabled for public view. "
				/>
			</Box>
		)
	}

	const entityId = eid ? +eid : 0
	const reportId = rid ? +rid : 0
	return (
		<Box h="calc(100vh - 60px)" mt="0">
			<LeftPanel entityId={entityId} reportId={reportId} tenantReport={data}></LeftPanel>
			<RightPanel></RightPanel>
		</Box>
	)
}

export default Dashboard
