import { Box, Button, Icon, Modal, Spinner, T, useToast } from '@veracity/vui'
import { ApiError, Entity as EntityType, FileDataPrefix, Report as ReportType } from 'libs/global'
import { useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { getExportFile } from 'apis/global'
import { PDFViewer } from 'global'
import { useEmbeddedConfig } from 'queries/global'
import { useGlobalStore } from 'stores/global'

import PublicLabel from '../../../labels/publicLabel'
import BreadCrumb from '../breadcrumb/breadcrumb'
import Image from '../image/image'
import Report from '../report/report'
import Toolbar from '../toolbar/toolbar'

const ReportSelector = props => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { selectedEntity, selectedReport } = useGlobalStore(
        state => ({
            selectedEntity: state.selectedEntity,
            selectedReport: state.selectedReport
        }),
        shallow
    )

    if (selectedReport && selectedReport.powerBIReportId) {
        return (
            <PowerBIReportSelector
                bookmarkId={searchParams.get('bookmarkId')}
                entity={selectedEntity}
                handleFullScreen={props.handleFullScreen}
                report={selectedReport}
            ></PowerBIReportSelector>
        )
    } else {
        return (
            <Box alignItems="center" justifyContent="center" mt="0" w="100%">
                <T size="lg" text="No report to display." />
            </Box>
        )
    }
}

type PowerBIReportSelectorProps = {
    bookmarkId: string
    entity: EntityType
    handleFullScreen: Function
    report: ReportType
}

export const PowerBIReportSelector = (props: PowerBIReportSelectorProps) => {
    const { report, bookmarkId, entity } = props
    const location = useLocation()
    const toast = useToast()

    const { data, isLoading, isError, error } = useEmbeddedConfig(props.entity.entityTreeId, props.report.id)
    const [printNow, setPrintNow] = useState(false)
    const [bookmarks, setBookmarks] = useState(null)
    const [currentBookmark, setCurrentBookmark] = useState(null)
    const [embedReport, setEmbedReport] = useState(null)
    const [exportFileTitle, setExportFileTitle] = useState('')
    const [exportFileContent, setExportFileContent] = useState('')
    const [exportFileNote, setExportFileNote] = useState('')
    const [exportModalOpen, setExportModalOpen] = useState(false)
    const [exportInProgress, setExportInProgress] = useState(false)
    const [modalErrors, setModalErrors] = useState([])

    const handlePrintReport = () => {
        if (['PDF', 'IMAGE'].includes(data.reportData?.reportType) && data.reportData.fileBlob && data.reportData.fileBlob.file) {
            window.print()
        } else if (report && report.powerBIReportId) {
            setPrintNow(true)
        }
    }

    const handleBookmarkSelect = bookmark => {
        bookmarks.forEach(bm => {
            if (bm.children) {
                bm.children.forEach(bmc => {
                    bmc.selected = bmc.name === bookmark.name
                })
            } else {
                bm.selected = bm.name === bookmark.name
            }
        })
        bookmark && bookmark.state && setCurrentBookmark(bookmark)
        setBookmarks(Array.from(bookmarks))
    }

    const downloadExportFile = (entityId, reportId, fileType, bookmark, reportName, dataType) => {
        const title = PublicLabel.exportFileCompleteTitle.replace('$fileType$', fileType.toUpperCase())
        const content = PublicLabel.exportFileCompleteContent.replace('$reportName$', reportName).replace('$fileType$', fileType.toUpperCase())
        let errors = []

        getExportFile(entityId, reportId, fileType, bookmark, null)
            .then(res => {
                if (res) {
                    if (res.statusCode && res.statusCode === 200) {
                        if (res.result) {
                            const path = `data:${dataType};base64,${res.result.file}`
                            const a = document.createElement('a')
                            a.href = path
                            a.download = reportName + res.result.fileExtension
                            document.body.appendChild(a)
                            a.click()
                            document.body.removeChild(a)
                        } else {
                            errors = [PublicLabel.exportFileNoData]
                        }
                    } else {
                        errors = [res.displayMessage]
                        if (res?.errorMessages?.length > 0) {
                            if (res?.errorMessages[0].toLowerCase().includes('incorrect config')) {
                                errors.push(PublicLabel.exportWrongConfig)
                            }
                            if (res?.errorMessages[0].toLowerCase().includes('badrequest')) {
                                errors.push(PublicLabel.exportBadRequest)
                            }
                        }
                    }
                }
            })
            .catch(error => {
                if (error.displayMessage && error.displayMessage.length > 0) {
                    errors = [error.displayMessage]
                }
                if (error?.errorMessages?.length > 0) {
                    if (error?.errorMessages[0].toLowerCase().includes('incorrect config')) {
                        errors.push(PublicLabel.exportWrongConfig)
                    }
                    if (error?.errorMessages[0].toLowerCase().includes('badrequest')) {
                        errors.push(PublicLabel.exportBadRequest)
                    }
                }
            })
            .finally(() => {
                setExportFileTitle(title)
                setExportFileContent(content)
                if (fileType === 'pptx') {
                    setExportFileNote(PublicLabel.exportFileCompleteNote)
                }
                setExportInProgress(false)
                setModalErrors(errors)
            })
    }

    const handleExportFile = fileType => {
        if (!fileType) return
        const fileDataPrefix = {
            pdf: 'application/pdf',
            pptx: 'application/pptx'
        }
        const dataType = FileDataPrefix[fileType]
        const title = PublicLabel.exportFileTitle.replace('$fileType$', fileType.toUpperCase())
        const content = PublicLabel.exportFileContent.replace('$reportName$', report.name).replace('$fileType$', fileType.toUpperCase())

        setExportModalOpen(true)
        setExportInProgress(true)
        setExportFileTitle(title)
        setExportFileContent(content)

        if (embedReport) {
            embedReport.bookmarksManager.capture().then(res => {
                downloadExportFile(entity.entityTreeId, report.id, fileType, res, report.name, dataType)
            })
        } else {
            downloadExportFile(entity.entityTreeId, report.id, fileType, null, report.name, dataType)
        }
    }

    const hideExportModal = () => {
        setExportInProgress(false)
        setExportModalOpen(false)
        setExportFileNote('')
        setModalErrors([])
    }

    const handlePrintDone = () => {
        setPrintNow(false)
    }

    const setupBookmarks = (bookmarks, embedReport) => {
        if (bookmarkId) {
            let activeBookmark
            bookmarks.forEach(bm => {
                if (bm.children) {
                    bm.children.forEach(bmc => {
                        if (bmc.name === bookmarkId) {
                            activeBookmark = bmc
                        }
                        bmc.selected = bmc.name === bookmarkId
                    })
                } else {
                    bm.selected = bm.name === bookmarkId
                }
            })
            setCurrentBookmark(activeBookmark)
        }
        setBookmarks(bookmarks)
        setEmbedReport(embedReport)
    }

    const handleBookmarkLink = bookmark => {
        if (bookmark) {
            const espacedName = window.encodeURI(bookmark.name)
            const host = window.location.origin
            const reportUrl = `${window.location.origin}${location.pathname}?bookmarkId=${espacedName}`

            navigator.clipboard.writeText(reportUrl).then(
                () => toast.showSuccess('The bookmark link is copied to Clipboard.'),
                err => {
                    console.error('Async: Could not copy text: ', err)
                }
            )
        }
    }

    const handleDownloadFile = () => {
        const fileType = data?.reportData?.fileBlob.fileExtension.replace('.', '').toLowerCase() || ''
        const dataType = FileDataPrefix[fileType]

        const a = document.createElement('a')
        a.href = `data:${dataType};base64,${data?.reportData.fileBlob.file}`
        a.download = report.name + data?.reportData.fileBlob?.fileExtension
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    if (isLoading) {
        return (
            <Box alignItems="center" h="100%" justifyContent="center" mt="0" w="100%">
                <Spinner size="lg" text="Loading Report Data…" textPosition="bottom" />
            </Box>
        )
    }

    if (isError) {
        return (
            <Box alignItems="center" h="100%" justifyContent="center" mt="0" w="100%">
                <T color="energyRed.54" size="lg" text={(error?.response?.data as ApiError).displayMessage} />
            </Box>
        )
    }

    if (['PBI', 'PREMIUM PBI'].includes(data.reportData?.reportType) && (!data.configuration?.accessToken || !data.configuration?.embeddedUrl)) {
        return (
            <Box alignItems="center" justifyContent="center" mt="0" w="100%">
                <T size="lg" text="Cann't load report, report parameter incorrect." />
            </Box>
        )
    }

    if (['PDF', 'IMAGE'].includes(data.reportData?.reportType) && (!data.reportData?.fileBlob || !data.reportData?.fileBlob.file)) {
        return (
            <Box alignItems="center" justifyContent="center" mt="0" w="100%">
                <T size="lg" text="The report resource can not be found." />
            </Box>
        )
    }

    return (
        <Box column h="100%" justifyContent="center">
            <Box alignItems="center" borderBottomColor="#e1e6e9" borderBottomWidth="1px" h="45px" justifyContent="space-between" pb="2px" pt="2px" w="100%">
                <BreadCrumb></BreadCrumb>
                <Toolbar
                    bookmarks={bookmarks}
                    handleBookmarkLink={handleBookmarkLink}
                    handleBookmarkSelect={handleBookmarkSelect}
                    handleDownloadFile={handleDownloadFile}
                    handleExportFile={handleExportFile}
                    handleFullScreen={props.handleFullScreen}
                    handlePrint={handlePrintReport}
                    reportData={data.reportData}
                ></Toolbar>
            </Box>

            {exportModalOpen && (
                <Modal className="export-file-modal" isOpen={exportModalOpen} onClose={() => setExportModalOpen(false)}>
                    <Box className="export-modal-wrap">
                        {exportInProgress ? (
                            <Box className="content">
                                <Box className="icon-content" pl="10" pr="10">
                                    <Spinner size="md" />
                                </Box>
                                <Box alignItems="top" className="text-content" column pl="10">
                                    <p>{exportFileTitle}</p>
                                    <div>{exportFileContent}</div>
                                </Box>
                            </Box>
                        ) : (
                            <div>
                                <div>
                                    {modalErrors.length > 0 ? (
                                        <Box className="content">
                                            <Box className="icon-content" pl="10" pr="10">
                                                <Icon cursor="pointer" name="falExclamationCircle" size="md"></Icon>
                                            </Box>
                                            <Box alignItems="top" className="text-content" column pl="10">
                                                <p>{PublicLabel.exportFileErrorTitle}</p>
                                                <div>
                                                    {modalErrors.map(error => (
                                                        <div key={error}>{error}</div>
                                                    ))}
                                                </div>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Box className="content">
                                            <Box className="icon-content" pl="10" pr="10">
                                                <Icon cursor="pointer" name="falCheckCircle" size="md"></Icon>
                                            </Box>
                                            <Box alignItems="top" className="text-content" column pl="10">
                                                <p>{exportFileTitle}</p>
                                                <div>{exportFileContent}</div>
                                                {exportFileNote && <div>{exportFileNote}</div>}
                                            </Box>
                                        </Box>
                                    )}
                                </div>
                                <Box justifyContent="end" mb="-15" mt="15" pl="10" pr="10">
                                    <Button className="btn btn-primary pull-right" onClick={() => hideExportModal()} size="md">
                                        OK
                                    </Button>
                                </Box>
                            </div>
                        )}
                    </Box>
                </Modal>
            )}

            {data && data.reportData?.reportType === 'IMAGE' && data.reportData.fileBlob && (
                <Box h="100%" justifyContent="center" overflow="auto" w="100%">
                    <Image printDone={handlePrintDone} printNow={printNow} reportData={data.reportData}></Image>
                </Box>
            )}
            {data && data.reportData?.reportType === 'PDF' && data.reportData.fileBlob && (
                <Box column h="calc(100% - 20px)" justifyContent="center" w="100%">
                    <PDFViewer id={data.reportData.id} report={data.reportData.fileBlob.file} />
                </Box>
            )}
            {['PBI', 'PREMIUM PBI', 'RDL'].indexOf(data.reportData?.reportType) >= 0 && (
                <Box column h="calc(100% - 20px)" justifyContent="center" w="100%">
                    <Report
                        currentBookmark={currentBookmark}
                        printDone={handlePrintDone}
                        printNow={printNow}
                        reportConfig={data.configuration}
                        reportData={data.reportData}
                        setupBookmarks={setupBookmarks}
                    ></Report>
                </Box>
            )}
        </Box>
    )
}

export default ReportSelector
